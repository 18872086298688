import React from "react";
import Layout from "../../components/layout";
import Lessons from "../../components/lessons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const BusinessOfPhotographyCourse = () => {
	const data = useStaticQuery(graphql`
		query {
			bpLesson1: file(relativePath: { eq: "thumbnails/bp-lesson1.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson2: file(relativePath: { eq: "thumbnails/bp-lesson2.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson3: file(relativePath: { eq: "thumbnails/bp-lesson3.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson4: file(relativePath: { eq: "thumbnails/bp-lesson4.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson5: file(relativePath: { eq: "thumbnails/bp-lesson5.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson6: file(relativePath: { eq: "thumbnails/bp-lesson6.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson7: file(relativePath: { eq: "thumbnails/bp-lesson7.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson8: file(relativePath: { eq: "thumbnails/bp-lesson8.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson9: file(relativePath: { eq: "thumbnails/bp-lesson9.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson10: file(relativePath: { eq: "thumbnails/bp-lesson10.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson11: file(relativePath: { eq: "thumbnails/bp-lesson11.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			bpLesson12: file(relativePath: { eq: "thumbnails/bp-lesson12.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
		}
	`);
	const bpLesson1 = data.bpLesson1.childImageSharp.gatsbyImageData;
	const bpLesson2 = data.bpLesson2.childImageSharp.gatsbyImageData;
	const bpLesson3 = data.bpLesson3.childImageSharp.gatsbyImageData;
	const bpLesson4 = data.bpLesson4.childImageSharp.gatsbyImageData;
	const bpLesson5 = data.bpLesson5.childImageSharp.gatsbyImageData;
	const bpLesson6 = data.bpLesson6.childImageSharp.gatsbyImageData;
	const bpLesson7 = data.bpLesson7.childImageSharp.gatsbyImageData;
	const bpLesson8 = data.bpLesson8.childImageSharp.gatsbyImageData;
	const bpLesson9 = data.bpLesson9.childImageSharp.gatsbyImageData;
	const bpLesson10 = data.bpLesson10.childImageSharp.gatsbyImageData;
	const bpLesson11 = data.bpLesson11.childImageSharp.gatsbyImageData;
	const bpLesson12 = data.bpLesson12.childImageSharp.gatsbyImageData;

	const lessons = [
		{
			heading: "Welcome to the Business of Photography",
			paragraphs: [
				"So, you’ve made the decision to launch a full-time photography business?",
				"Firstly congratulations! It doesn’t matter how you arrived at this decision and what, if anything happened to get you to this point.",
				"All you need to know is you’re about to take back control of your life, and even though it will be hard work and tough at times, this is not a 9-5 job. You will find yourself working longer, harder but with the flexibility to work around your family. You are in control and it’s about to get exciting.",
				"I’m here to help you get up and running, from building your brand, choosing a logo and name to looking at your target market, depending on the type of photography you’ve chosen. People need to know about you, trust you and believe in you, so we’re going to look at social media, networking, and building trust as an expert photographer.",
				"I’m super excited to share with you my 6 modules of how I launched my business. These will guide you to creating a business with paying customers.",
				"The first thing you'll need to do is head to the panel on the right hand side of your screen and download your course workbook. That's going to provide accompanying exercises and materials for you throughout the course.",
				"Now, let’s jump in with Module 1, “Creating a killer brand”!",
				"Go to your membership dashboard and watch video 1, it’s only ten minutes, then download the workbook that accompanies the course and start to work through your action points for module 1.",
				"From the workbook online you have access to the private Facebook group where you can ask me and others going through the course questions, and share your own ideas.",
				"It’s great to have you here and I’ll see you in Module 1!",
			],

			downloads: [
				{
					title: "Workbook",
					link: "https://drive.google.com/file/d/1_SDeioevjHSmr-uNzriCZqyVPtp2Flwp/view?ts=611285b6",
				},
			],
		},
		{
			heading: "Creating a Killer Brand: Part 1",
			paragraphs: [
				"Welcome to Module 1, all about creating your Killer Brand. I hope you've downloaded your workbook and are ready to begin!",
				"Creating your brand is one of the most important parts of your business. In fact, it's quite hard to get started if you don't have continuity and structure for your brand presence.",
				"Your brand is more than just a logo, it's the experience your customers have with your business!",
				"So, let's dive in and look at how you can create your business's 'Mission Statement' to get your brand off to the right start...",
				"Don't forget to download your bonuses and resources for this module from the bar on the right!",
			],

			downloads: null,
		},
		{
			heading: "Creating a Killer Brand Part 2:",
			paragraphs: [
				"Now that you have your mission statement ready, it's time to look at your branding face, which starts with colours and fonts!",
				"Don't worry, we'll go through these together step by step. Plus, in your workbook you'll find information about what each colour conveys to your potential clients, and how you can choose the right ones for you!",
			],
			downloads: [
				{
					title: "The Colour Wheel",
					link: "https://drive.google.com/file/d/1POlt8Z-mjAJHVbIW8QcNqOBq52UzIaWO/view?ts=61128710",
				},
				{
					title: "Hex Colours and how to use them",
					link: "https://drive.google.com/file/d/11FiuZkJJQnABX3EWmnfRpD_Y0DPkWPnq/view?ts=6112879f",
				},
			],
		},
		{
			heading: "Your Ideal Client",
			paragraphs: [
				"Welcome to Module 2, where we'll uncover and clarify your Ideal Client.",
				"Every business needs a customer or client avatar, which provides a guide for who you want to speak to and market to as a business.",
				"Brands and businesses who try to appeal to everyone, actually end up appealing to nobody, so let's dive in and get specific about who you want to work with as a photographer?",
				"Don't forget to download your bonuses and resources for this module from the bar on the right!",
			],
			downloads: null,
		},
		{
			heading: "Communication and Onboarding",
			paragraphs: [
				"Welcome to Module 3, all about Communication and Onboarding. This is a vital part of running your business.",
				"Finding new clients, keeping them and driving new sales all takes time, but it’s a step you can’t skip if you want to be successful!",
				"Remember to post into the Facebook group ideas, work you’ve finished and also ask questions if you get stuck.",
				"Don't forget to download your bonuses and resources for this module from the bar on the right!",
			],
			downloads: [
				{
					title: "Wedding Questions Form",
					link: "https://drive.google.com/file/d/1sm206ZfFuwhovJsGsF3Ed5mpEQaiMBgf/view?ts=611287ec",
				},
			],
		},
		{
			heading: "Pricing and Packages: Part 1",
			paragraphs: [
				"Welcome to Module 4. We’re now over halfway through the course so well done for making it this far! By now your workbook should be filing up nicely and becoming a working reference for the route map to your new business.",
				"You should by now have a clear idea of what you want your business to look like, by ways of a logo, branding colours, name, mission, statement and you should have a picture in your mind of your ideal customer, have an idea of where they hang out on social media and how to target them.",
				"Now, onto pricing and packaging. We’re going to look at creating packages that are priced correctly as well as hourly, half and daily rates that you’ll feel comfortable charging.",
				"So let's start by diving into the most common question I get asked:",
				"How much does a photographer charge?",
			],
			downloads: null,
		},
		{
			heading: "Pricing and Packages: Part 2",
			paragraphs: [
				"Welcome to Part 2 of Module 4, which is all about contracts, terms of business conditions and invoicing",
				"By now you should have your prices ready to go for either day and half day rates, and packages you'd like to sell.",
				"Next we need to introduce some terms of business. Why do we need this? Well, being upfront is essential in business and just makes sure no one is disappointed or gets a surprise they weren’t expecting!",
				"Don't forget to download your bonuses and resources for this module from the bar on the right!",
			],
			downloads: [
				{
					title: "Invoice Template",
					link: "https://drive.google.com/file/d/1zxyYPkKNgsVF4_zKgFB4sr-9dEBYyvLI/view?ts=61128781",
				},
				{
					title: "Commercial Photography T&C Template",
					link: "https://kajabi-storefronts-production.s3.amazonaws.com/posts/14085888/downloads/RHJd0eFRbWEW18tyM14U_Commercial_Photography_Terms_Conditions_Template.docx?response-content-disposition=attachment%3B%20filename%3DCommercial_Photography_Terms_Conditions_Template.docx&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAI4TIKYMSB4PQMFBA%2F20210826%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210826T101547Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f7e2fa69a0d3bd89961d7587c4b4bf952b1c878275f7a34d9ec262e3ee6afb50",
				},
				{
					title: "Photography Contract Template",
					link: "https://drive.google.com/file/d/1ityl6nibDzpfvJpk1TIKX1jbgGzoUGpd/view?ts=61128781",
				},
			],
		},
		{
			heading: "Workflow, Systems and Processes",
			paragraphs: [
				"Welcome to Module 5, all about Repeatable Workflow Systems and Software.",
				"Workflows can help streamline and automate repeatable business tasks, minimizing room for errors and increasing overall efficiency.",
				"Before we start, it's worth noting that the workflow process can vary greatly from one photographer to another, because of too many variables involved and because there is no established, standard workflow that applies to everyone.",
				"There's a bonus video in the downloads for this module about a company called Studio Ninja. These guys have built a system which automates workflows for photographers. So do take a look at this video!",
				"Don't forget to download your bonuses and resources for this module from the bar on the right!",
			],
			downloads: null,
		},
		{
			heading: "Inside Studio Ninja Software",
			paragraphs: ["Inside the Studio Ninja"],
			downloads: null,
		},
		{
			heading: "Marketing your business: Part 1",
			paragraphs: [
				"Welcome to Module 6 and the final part of your Business of Photography Course - Marketing Your Business!",
				"This is all about Marketing your new business to the world and gaining traction with followers, fans, advocates of your brand and paying customers.",
				"Because it's the last training, this one is a little longer than the rest, so I've split it into 3 videos. That way you can watch one at a time or grab a cup of tea and settle in for the full hour! Let's do this!",
				"Let's get started with Part 1...",
				"Don't forget to download your bonuses and resources for this module from the bar on the right!",
			],
			downloads: null,
		},
		{
			heading: "Marketing your business: Part 2",
			paragraphs: ["Now let's dive into Part 2!"],
			downloads: null,
		},
		{
			heading: "Marketing your business: Part 3",
			paragraphs: ["And finally, Part 3!"],
			downloads: null,
		},
	];
	const pages = [
		[
			{
				id: 1,
				videoImg: bpLesson1,
				videoLink:
					"https://drive.google.com/file/d/1nKpM1tX_y3GH0slK_1ptMAiaggQxx83w/preview",

				description: "Welcome to the Business of Photography",
				lessonIndex: 0,
			},
			{
				id: 2,
				videoImg: bpLesson2,
				videoLink:
					"https://drive.google.com/file/d/1XUTncOygz8FpGW-NY5IK2MwCZZrqLORM/preview",

				description: "Creating a Killer Brand: Part 1",
				lessonIndex: 1,
			},
			{
				id: 3,
				videoImg: bpLesson3,
				videoLink:
					"https://drive.google.com/file/d/1_mizKpusmqlUVwI9REQUtPtCNs_N_pFB/preview",

				description: "Creating a Killer Brand Part 2",
				lessonIndex: 2,
			},
		],
		[
			{
				id: 4,
				videoImg: bpLesson4,
				videoLink:
					"https://drive.google.com/file/d/1e1ZPL1bZnNgXFWbvMWBzZaWaZqGq86xq/preview",

				description: "Your Ideal Client",
				lessonIndex: 3,
			},
			{
				id: 5,
				videoImg: bpLesson5,

				videoLink:
					"https://drive.google.com/file/d/1lrZH82_F0nTujrWmmUrWbyJYiF1DXEUO/preview",

				description: "Communication and Onboarding",
				lessonIndex: 4,
			},
			{
				id: 6,
				videoImg: bpLesson6,
				videoLink:
					"https://drive.google.com/file/d/1yVhRPFOZUmXC7g1uRMR_1lyWyv4j24Pa/preview",

				description: "Pricing and Packages: Part 1",
				lessonIndex: 5,
			},
		],
		[
			{
				id: 7,
				videoImg: bpLesson7,
				videoLink:
					"https://drive.google.com/file/d/1luG48MbMbzJRWWksjDRqzY9XEUYOfHiS/preview",

				description: "Pricing and Packages: Part 2",
				lessonIndex: 6,
			},
			{
				id: 8,
				videoImg: bpLesson8,

				videoLink:
					"https://drive.google.com/file/d/1-5rz8IUO7Pu8bkjP_Y_rPqKxcGHsSm64/preview",

				description: "Workflow, Systems and Processes",
				lessonIndex: 7,
			},
			{
				id: 9,
				videoImg: bpLesson9,
				videoLink:
					"https://drive.google.com/file/d/1ZLDNL53Jljeb5wRY8xwmX2gqjBkvyelx/preview",

				description: "Inside Studio Ninja Software",
				lessonIndex: 8,
			},
		],
		[
			{
				id: 10,
				videoImg: bpLesson10,
				videoLink:
					"https://drive.google.com/file/d/1gFFQkR0LapAdLl_9mkOIh3VHwrlAVFap/preview",

				description: "Marketing your business: Part 1",
				lessonIndex: 9,
			},
			{
				id: 11,
				videoImg: bpLesson11,

				videoLink:
					"https://drive.google.com/file/d/1vW4zNmNlC4EO47MJRaHcoqeZs7Jlg4CS/preview",

				description: "Marketing your business: Part 2",
				lessonIndex: 10,
			},
			{
				id: 12,
				videoImg: bpLesson12,
				videoLink:
					"https://drive.google.com/file/d/1eun0f-dAsh4VxaqtmmUGfaEPbKJM3nxD/preview",

				description: "Marketing your business: Part 3",
				lessonIndex: 11,
			},
		],
	];

	return (
		<Layout>
			{/* <div>
				<meta name="robots" content="noindex, nofollow" />
				<style
					dangerouslySetInnerHTML={{
						__html: `#__memberspace_modal_protected_page {position:fixed; top:0; left:0; width:100%; height:100%; background:#fff; z-index:2147483646;}`,
					}}
				/>
				<div id="__memberspace_modal_protected_page"> </div>
			</div>
			<GatsbySeo title="Business of photography course" />
			<section className="pt-6">
				<Container>
					<Row className="pb-5">
						<Col>
							<h1 className="text-center">Business Of Photography Course</h1>
							<hr className="w-25 mx-auto" />
						</Col>
					</Row>
					<Lessons lessons={lessons} pages={pages} />
				</Container>
			</section> */}
		</Layout>
	);
};

export default BusinessOfPhotographyCourse;
